import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Brucan Pubs Customer Story - Rotaready"
          description="Learn how Rotaready helped Brucan Pubs significantly reduce admin heavy tasks, improve operational efficiency and better deploy 21 hours every week as a result"
          url="customer-stories/hospitality-brucan-pubs"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="The Greyhound pub exterior with flowers"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Brucan Pubs logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Learn how Rotaready helped Brucan Pubs significantly reduce admin heavy tasks, improve operational efficiency and better deploy 21 hours every week as a result" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="4" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="124" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="688%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="21 hours" />
              <StatSubtitle text="better deployed every week!" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Brucan Pubs" />
              </Content>

              <Content>
                <TextBlock text="Brucan Pubs was founded in 2018 by culinary duo, James Lyon-Shaw and Jamie Dobbin. Since opening its first pub in Windsor, the business has seen great growth and now consists of four venues, which offer equally exceptional service and menus following an aggressively seasonal structure." />
              </Content>

              <Content>
                <TextBlock text="Prior to implementing Rotaready, the Brucan Pubs team were using spreadsheets to build staff rotas, store new employee information and track employee attendance & holiday. All operational processes were very manual and as the business grew, the need for a more efficient approach was highlighted." />
              </Content>

              <Content>
                <Blockquote
                  text="As we grew, we needed to take a leap into the digital age and professionalise our whole set up. We had employees moving between sites and data in 3-4 places, so bringing it all into one place, one piece of software, was key."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>

              <Content>
                <Blockquote
                  text="We wanted to streamline every step of the employee journey, reduce the amount of paper we used and the number of clicks managers had to do. Now, thanks to Rotaready, nothing is more than 2 clicks away."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A leap into the digital age" />
              </Content>

              <Content>
                <TextBlock text="In February 2022, the Brucan Pubs team set about their search for a workforce management solution that could help them resolve the issues they were facing; and free up time to focus on delivering the exceptional service that they pride themselves on. The chosen solution also needed to be cost effective and have good functionality without being robotic." />
              </Content>

              <Content>
                <TextBlock text="After reviewing a number of options, Brucan Pubs turned to Rotaready to help them tackle the challenges they were facing." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Collage of a cosy pub interior, cheesy bread and a pint of ale"
              />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Ultimately our decision to go with Rotaready came down to a number of factors. It actually came in under the budget we had allocated for the project, which we were obviously impressed by. Plus it ticked 9/10 boxes in terms functionally and was a lot smoother to implement than any other solutions we'd seen."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>

              <Content>
                <TextBlock text="From making the decision to go with the rota software, the pub group had all sites up and running with Rotaready within a couple of weeks. This included an integration with the businesses EPOS and reservation systems." />
              </Content>

              <Content>
                <Blockquote
                  text="Implementing Rotaready was very straightforward, everything was done remotely with all of our historical data accurately imported. Generally Rotaready is a really easy and intuitive bit of software to use. Even the less computer savvy were quickly brought up to speed."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Brucan Pubs have since opened a fourth site which was also seamlessly set up with Rotaready..." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Opening a new site with Rotaready in place was SO much easier. When you open a new pub there are enough stresses without having to worry about the admin; Rotaready enabled me to do what I needed to in an hour rather than a whole day."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Enhanced, Extensive, Effortless" />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready, it's not only been easier for Brucan Pubs to build accurate staff rotas; the business has seen a great number of benefits. Here's what the team told us are their favourite 3 things about the rota software..." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="1. Enhanced visibility" />
              </Content>

              <Content>
                <TextBlock text="Visibility has improved for all employees - generally Head Office can now see the ‘bigger picture'. Employee records can be immediately accessed and there's full visibility of rotas and costs across sites. As for managers, they can easily find employee information such as hours worked, contracted hours and holiday entitlement." />
              </Content>

              <Content>
                <TextBlock text="And employees now have full visibility over upcoming shifts and are able to book time off via Rotaready's mobile app. Both things they would previously have had to request from their manager." />
              </Content>

              <Content>
                <Blockquote
                  text="The permission levels within Rotaready are great, it means the right people have eyes on the right things! It works incredibly well on all levels."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>

              <Content>
                <H4 uistyle="primary" text="2. Extensive reporting" />
              </Content>

              <Content>
                <TextBlock text="Day to day reporting was previously laborious for the pub group and it was difficult to find accurate employee information. But since getting started with Rotaready, the team at Brucan Pubs has access to a multitude of reports; something they have found to be particularly useful, especially when it comes to reporting on joiners and leavers." />
              </Content>

              <Content>
                <TextBlock>
                  Rotaready's reporting has also helped improve payroll accuracy as reliable timesheet data is pushed directly from Rotaready into Brucan Pubs <Link style="color:inherit" to="/payroll">payroll</Link> solution.
                </TextBlock>
              </Content>

              <Content>
                <H4 uistyle="primary" text="3. Effortless onboarding" />
              </Content>

              <Content>
                <TextBlock text="Employee onboarding is now easier than ever for Brucan Pubs. With Rotaready the team can be sure that all employees are set-up correctly from day one." />
              </Content>

              <Content>
                <TextBlock text="The team has also recently switched on one of Rotaready's newest features, self- onboarding; enabling new employees to set themselves up on Rotaready by submitting their details directly via the Rotaready app. This has removed the need for any paper forms and as a result improved data security and eliminated the risk of human error when translating employee information from its paper format into Rotaready." />
              </Content>

              <Content>
                <Blockquote
                  text="The value Rotaready adds can be measured by the smile it puts on my face when I know it's not going to take me half a day to get a new employee set up. 10 clicks is now 2 clicks with Rotaready. It saves us time which saves us money and head space!"
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all these factors has helped Brucan Pubs save over 21 hours every week and achieve an ROI of 688%!" />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Brucan Pubs still have a number of features they could be utilising to help make things even more efficient. Next on their agenda is to use Rotaready to automatically issue contracts to new employees and store them within Rotaready. This will help them reduce the number of clicks and processes even further than they have already." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Sticky toffee pudding with a ball of ice cream placed on top"
              />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Rotaready are always forthcoming with developments, so much so that there are features we aren't even using yet. I have often queried something with our Customer Success Manager and found out that what I am asking for is already on the way. Nothing is ever standing still."
                  attribution="Nick Solomides, HR & Recruitment Manager"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_brucanpubs"
                label="rotaready.com/customer-stories/hospitality-brucan-pubs"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Brucan-Pubs.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/brucan-pubs/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/brucan-pubs/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/brucan-pubs/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/brucan-pubs/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
